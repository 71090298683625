<template>
  <SidebarFluid
    title="Занятия"
    :prop="day.dateString"
    @close="emit('close')"
    show-coins
  >
    <div class="slot-list" :class="{ pending: loading }" v-if="slots.length > 0">
      <MentorBookSlotCard
        v-for="(slot, i) in slots"
        :key="i"
        :book-slot="slot"
        v-model:selected="slot.selected"
        :price="mentor.mentor_price_with_commission"
      />
    </div>
    <div class="empty" v-else>Нет доступных бронирований</div>
    <div class="actions" v-if="slots.length > 0">
      <div class="total">
        <div class="name">Итого</div>
        <div class="value">{{ total }}<IconCurrencyRubel class="icon" /></div>
      </div>
      <PrimaryButton v-if="isEnough" class="book-button" @click="submit" :class="{ disabled: selected.length === 0 || loading }">Забронировать</PrimaryButton>
      <template v-else>
        <form ref="formRef" class="payment-form" @submit.prevent="submitPayment">
          <input type="hidden" name="frame" value="false">
          <input type="hidden" name="language" value="ru">
          <input type="hidden" name="description" value="Пополнение баланса">
          <input type="hidden" name="name"
                 :value="`${user.personal.first_name} ${user.personal.last_name} ${user.personal.middle_name ?? ''}`">
          <input type="hidden" name="email" :value="user.email">
          <input type="hidden" name="phone" :value="user.phone ?? ''">
          <input type="hidden" name="order" id="formOrder">
          <input type="hidden" name="terminalkey" id="formTerminal">

          <input type="hidden" name="amount" :value="total - user.coins_total">

          <PrimaryButton class="book-button" :class="{ disabled: selected.length === 0 || loading }">
            Пополнить {{ total - user.coins_total }} <IconCurrencyRubel class="icon" />
          </PrimaryButton>
        </form>
      </template>
      <div :style="{ opacity: +(Object.keys(errors).length > 0) }" class="error">Что-то пошло не так. Попробуйте еще раз.</div>
    </div>
  </SidebarFluid>
</template>

<script setup>
import {IconCurrency, IconCurrencyRubel} from '@tabler/icons-vue'
import SidebarFluid from '@/components/common/SidebarFluid'
import MentorBookSlotCard from '@/components/mentor/MentorBookSlotCard'
import PrimaryButton from '@/components/common/PrimaryButton'
import { bookMentor } from '@/api/mentoring'
import useForm from '~/composables/useForm'
import {getPaymentData} from "~/api/payment";

const props = defineProps({
  day: {
    type: Object,
    required: true
  },
  mentor: {
    type: Object,
    required: true
  }
})
const emit = defineEmits(['close'])

const router = useRouter()
const route = useRoute()
const user = inject('user')
const updateUserInfo = inject('updateUserInfo')

const isUnavailable = slot => {
  let unavailable = [false, null];
  ['books', 'my_books'].some(key => {
    props.mentor[key].some(book => {
      if (!(slot.start.getTime() === book.datetime_start.getTime() && slot.end.getTime() === book.datetime_end.getTime()) && ![slot.start, slot.end].some(d => d > book.datetime_start && d < book.datetime_end)) return false
      unavailable = [true, key === 'books' ? 'Забронировано' : 'Недоступно']
      return true
    })
    return unavailable[0]
  })
  return unavailable
}

const date = computed(() => `${props.day.year}-${(props.day.month + 1).toString().padStart(2, '0')}-${props.day.day.toString().padStart(2, '0')}`)

let lessons = []
if (route.query.lessons) {
  const lessonsArray = route.query.lessons.split(',')

  lessons = lessonsArray.map(date => {
    const time = date.split(' ')[1]

    return ~~time.split(':')[0]
  })
}

const slots = reactive(
  Array(24)
    .fill(null)
    .map((_, i) => {
      const slot = {
        start: new Date(`${date.value} ${i}:00:00`),
        end: new Date(`${date.value} ${i + 1}:00:00`),
        selected: false
      }

      if (lessons.includes(slot.start.getHours())) {
        slot.selected = true
      }

      return Object.assign({}, slot, { unavailable: isUnavailable(slot) })
    }).filter(s => new Date() < s.start)
)

const selected = computed(() => slots.filter(s => s.selected))
const total = computed(() => selected.value.length * props.mentor.mentor_price_with_commission)
const isEnough = computed(() => user.value.coins_total >= total.value)

const { form, loading, errors, submit } = useForm(
  () => bookMentor(props.mentor.id, { datetimes_start: selected.value.map(s => s.start.toISOString()) }),
  data => {
    updateUserInfo()
    router.replace({ name: 'mentor-lessons', params: { id: props.mentor.id } })
  }
)

const formRef = ref()

const submitPayment = async () => {
  const orderData = await getPaymentData({
    mentor_id: props.mentor.id,
    datetimes_start: selected.value.map(s => {
      const day = ('0' + s.start.getDate()).slice(-2)
      const month = ('0' + (s.start.getMonth() + 1)).slice(-2)
      const year = s.start.getFullYear()
      const time = ('0' + s.start.getHours()).slice(-2) + ':' + ('0' + s.start.getMinutes()).slice(-2)

      return `${day}.${month}.${year} ${time}`
    })
  })

  document.querySelector('#formOrder').value = orderData.order_id
  document.querySelector('#formTerminal').value = orderData.terminal_key

  await nextTick()
  return await window.pay?.(formRef.value)
}

useScriptTag('https://securepay.tinkoff.ru/html/payForm/js/tinkoff_v2.js')
</script>

<style scoped lang="scss">
.slot-list {
  & > * {
    margin-bottom: 10px;
  }
}

.total {
  margin-top: 24px;
  & .name {
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: var(--color-primary)
  }
  & .value {
    font-weight: 800;
    font-size: 28px;
    line-height: 34px;
    display: flex;
    align-items: center;
    & .icon {
      margin-left: 3px;
      width: 30px;
      height: 30px;
      color: var(--color-elements-secondary);
    }
  }
}

.empty {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 50px;
  color: var(--color-elements-tertiary);
}

.error {
  color: var(--color-error);
  margin-top: 30px;
}

.book-button {
  margin-top: 30px;
  display: flex;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.actions {
  background: var(--color-bg);
  position: sticky;
  bottom: 0;
  right: 0;
  height: 280px;
  width: 100%;
  padding-top: 10px;
  transform: translateY(40px);
}
.primary-button svg {
  display: inline-block;
  vertical-align: top;
}
.primary-button svg path {
  stroke-width: 3px;
}
</style>
